import config from '../config.json';
import {ProgressBar} from 'react-bootstrap';
import { t } from 'i18next';

function Skills() {

    return (
        <section id="skills" class="skills section-bg">
                <div class="container" data-aos="fade-up">
                <div class="section-title">
                    <h2>{t('Skills')}</h2>
                </div>
                <div class="row skills-content">
                    <div class="col-lg-12">
                        {config.skills.map(function (object) {
                            return (
                            <div class="progress">
                                <span class="skill">{object.name} <i class="val">{object.percent}%</i></span>
                                <ProgressBar now={object.percent} variant={object.percent > 80 ? 'success': null} label={object.text} />
                            </div>)
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Skills;