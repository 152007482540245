import config from '../config.json';
import { t } from 'i18next';

function About() {

    const dob = new Date(config.dob);  
    //calculate month difference from current date in time  
    const month_diff = Date.now() - dob.getTime();  
      
    //convert the calculated difference in date format  
    const age_dt = new Date(month_diff);   
      
    //extract year from date      
    const year = age_dt.getUTCFullYear();  

    const aged = Math.abs(year - 1970);
    const birthday = dob.getFullYear();

    return (
      <section id="about" class="about">
        <div class="container" data-aos="fade-up">

          <div class="section-title">
            <h2>{t('About')}</h2>
            <p></p>
          </div>

          <div class="row">
            <div class="col-lg-4">
              &nbsp;
            </div>
            <div class="col-lg-8 pt-4 pt-lg-0 content">
              <h3>Web Developer.</h3>
              <p class="fst-italic">
                
              </p>
              <div class="row">
                <div class="col-lg-6">
                  <ul>
                    <li><i class="bi bi-chevron-right"></i> <strong>{t('Birthday')}:</strong> <span>{birthday}</span></li>
                    <li><i class="bi bi-chevron-right"></i> <strong>{t('Website')}:</strong> <span><a href={config.website}>{config.website}</a></span></li>
                    <li><i class="bi bi-chevron-right"></i> <strong>{t('Address')}:</strong> <span>{config.address}</span></li>
                    <li><i class="bi bi-chevron-right"></i> <strong>{t('Enterprise Id')}:</strong> <span>{config.enterprise_id}</span></li>
                  </ul>
                </div>
                <div class="col-lg-6">
                  <ul>
                    <li><i class="bi bi-chevron-right"></i> <strong>{t('Age')}:</strong> <span>{aged}</span></li>
                    <li><i class="bi bi-chevron-right"></i> <strong>{t('E-mail')}:</strong> <span><a href={`mailto: ${config.email}`}>{config.email}</a></span></li>
                    <li><i class="bi bi-chevron-right"></i> <strong>{t('Phone')}:</strong> <span><a href={`tel: ${config.phone}`}>{config.phone}</a></span></li>
                  </ul>
                </div>
              </div>
              <p>

              </p>
            </div>
          </div>

        </div>
      </section>
    )
}

export default About;