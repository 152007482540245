import MobileNav from './components/MobileNav';
import Header from './components/Header';
import Hero from './components/Hero';
import Footer from './components/Footer';
import Skills from './components/Skills';
import Contact from './components/Contact';
import Services from './components/Services';
import About from './components/About';
import Resume from './components/Resume';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import './App.css';
import i18n_fr from './components/i18n/fr.json';
import i18n_en from './components/i18n/fr.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: i18n_en
      },
      fr: {
        translation: i18n_fr
      }
    },
    lng: "fr", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

function App() {
  return (
    <div className="App">
      <MobileNav />
      <Header />
      <Hero />
      <div id="main">
        <About />
        <Skills />
        <Resume />
        <Services />
        <Contact />
      </div>
      <Footer />
    </div>
  );
}

export default App;
