import config from '../config.json';
import { t } from 'i18next';

function Resume() {

    return (
      <section id="resume" class="resume">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>{t('Resume')}</h2>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <h3 class="resume-title">{t('Sumary')}</h3>
            <div class="resume-item pb-0">
              <h4>{config.name}</h4>
              <p><em>{config.description}</em></p>
              <ul>
                <li>{config.address}</li>
                <li>{config.phone}</li>
                <li>{config.email}</li>
              </ul>
            </div>
            <h3 class="resume-title">{t('Education')}</h3>
            {config.resume.educations.map(function(object, i){
                return (
                <div class="resume-item">
                  <h4>{object.name}</h4>
                  <h5>{object.date}</h5>
                  <p><em>{object.school}</em></p>
                </div>);
            })}
          </div>
          <div class="col-lg-6">
            <h3 class="resume-title">{t('Professional Experience')}</h3>
            {config.resume.works.map(function(object, i){
                return (
                <div class="resume-item">
                  <h4>{object.name}</h4>
                  <h5>{object.date}</h5>
                  <p><em>{object.work} {object.website && <a href={object.website}><i class="bx bx-globe"></i></a>}</em></p>
                  { object.description && (
                  <ul>
                    <li>{object.description}</li>
                  </ul>)}
                </div>);
            })}
          </div>
        </div>
      </div>
    </section>
    )
}

export default Resume;