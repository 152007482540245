import React, { useRef, useEffect } from 'react';
import Typed from 'typed.js';
import './Hero.css';
import config from '../config.json';
import { t } from 'i18next';

function Hero() {
  // Create reference to store the DOM element containing the animation
  const el = useRef(null);
  // Create reference to store the Typed instance itself
  const typed = useRef(null);

  useEffect(() => {
    const options = {
      strings: config.skills_typed,
      typeSpeed: 50,
      backSpeed: 50,
    };
    
    // elRef refers to the <span> rendered below
    typed.current = new Typed(el.current, options);
    
    return () => {
      // Make sure to destroy Typed instance during cleanup
      // to prevent memory leaks
      typed.current.destroy();
    }
  }, [])

  return (
    <section id="hero" class="d-flex flex-column justify-content-center">
      <div class="container">
        <h1>{config.name}</h1>
        <p>{t('I\'m')} <span class="typed" ref={el}></span></p>
        <div class="social-links">
          <a href={config.links.linkedin} class="linkedin"><i class="bx bxl-linkedin"></i></a>
        </div>
      </div>
    </section>
  );
}

export default Hero;
