import config from '../config.json';
import { t } from 'i18next';
import {Component} from 'react';
import MD5 from "crypto-js/md5";

class Contact extends Component {

  constructor(props) {
    super(props);
    this.state = {name: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const value = event.target.value;
    this.setState({
      ...this.state,
      [event.target.name]: value
    });
  }

  getAuthHeader() {
    return 'Bearer '+MD5(config.form_salt+this.state.email).toString();
  }

  sendByMail(state) {
    window.open('mailto:'+config.email+'?subject='+state.subject+'&body='+state.name+"\n"+state.message);
  }

  handleSubmit(event) {
    event.preventDefault();
    let _this = this;
    if (config.form_endpoint) {
      let data = {
        name: this.state.name,
        email: this.state.email,
        subject: this.state.subject,
        message: this.state.message,
      };
      let config = {
        mode: 'no-cors',
        method: "POST",
        headers: {
          'Authorization': this.getAuthHeader()
        },
        body: data
      };
      console.error('config', config);
      fetch(config.form_endpoint, config)
      .then(function(response) {
        alert("Message envoyé");
      })
      .catch(function () {
        alert("Message non envoyé");
        _this.sendByMail(_this.state);
      });
    } else {
      this.sendByMail(this.state);
    }
  }

  render() {
      return (
        <section id="contact" class="contact">
          <div class="container" data-aos="fade-up">
            <div class="section-title">
              <h2>{t('Contact Us')}</h2>
            </div>
            <div class="row mt-1">
              <div class="col-lg-4">
                <div class="info">
                  <div class="address">
                    <i class="bi bi-geo-alt"></i>
                    <h4>{t('Address')}:</h4>
                    <p>{config.address}</p>
                  </div>
                  <div class="email">
                    <i class="bi bi-envelope"></i>
                    <h4>{t('E-mail')}:</h4>
                    <p>{config.email}</p>
                  </div>
                  <div class="phone">
                    <i class="bi bi-phone"></i>
                    <h4>{t('Phone')}:</h4>
                    <p>{config.phone}</p>
                  </div>
                  <div class="enterprise">
                    <i class="bi bi-building"></i>
                    <h4>{t('Enterprise Id')}:</h4>
                    <p>{config.enterprise_id}</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-8 mt-5 mt-lg-0">
                <form onSubmit={this.handleSubmit} method="post" class="php-email-form">
                  <div class="row">
                    <div class="col-md-6 form-group">
                      <input type="text" name="name" value={this.state.name} class="form-control" id="name" placeholder={t('Your name')} onChange={this.handleChange} required />
                    </div>
                    <div class="col-md-6 form-group mt-3 mt-md-0">
                      <input type="email" class="form-control" name="email" value={this.state.email} id="email" placeholder={t('Your E-mail')} onChange={this.handleChange} required />
                    </div>
                  </div>
                  <div class="form-group mt-3">
                    <input type="text" class="form-control" name="subject" value={this.state.subject} id="subject" placeholder={t('Subject')} onChange={this.handleChange} required />
                  </div>
                  <div class="form-group mt-3">
                    <textarea class="form-control" name="message" value={this.state.message} rows="5" placeholder={t('Message')} onChange={this.handleChange} required></textarea>
                  </div>
                  <div class="my-3">
                    <div class="loading">Loading</div>
                    <div class="error-message"></div>
                    <div class="sent-message">Your message has been sent. Thank you!</div>
                  </div>
                  <div class="text-center"><button type="submit">{t('Send message')}</button></div>
                </form>
              </div>
            </div>
          </div>
      </section>
    )
  }
}

export default Contact;