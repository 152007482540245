import config from '../config.json';

function Footer() {
  const today = new Date();
  const year = today.getFullYear();
  return (
  <footer id="footer">
      <div class="container">
        <h3>{config.name}</h3>
        <p></p>
        <div class="social-links">
          <a href={config.links.linkedin} class="linkedin"><i class="bx bxl-linkedin"></i></a>
        </div>
        <div class="copyright">
          {year} &copy; Copyright <strong><span>{config.name}</span></strong>. All Rights Reserved
        </div>
        <div class="credits">
          Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
        </div>
      </div>
  </footer>
  );
}

export default Footer;
